export function graph() {

    const graph = document.querySelector("#graph-data");
    if(!graph) return;

    // define classes and colors
    const transparentClass = "graph-group-transparent";
    const mainColor = "#00aeef";
    const activeLinkClass = "io-sync-chart-list__item--active";

    // get all target connections
    const targetLinks = document.querySelectorAll("[data-graph-target]");

    // make all connections transparent
    const connections = graph.querySelectorAll("#curves > path");
    const makeAllLinksTransparent = () => {
        connections.forEach(link => {
            link.classList.add(transparentClass);
        })
    }
    const showAllLinks = () => {
        connections.forEach(link => {
            link.classList.remove(transparentClass);
        })
    }

    // make all groups transparent
    const graphGroups = graph.querySelectorAll("#groups > g");
    const makeAllGroupsTransparent = () => {
        graphGroups.forEach(group => {
            group.classList.add(transparentClass);

            // in target group find path and change fill color inline style
            const targetGroupPath = group.querySelector("path");
            targetGroupPath.setAttribute("style", `fill: #fff`);
        })
    }
    const showAllGroups = () => {
        graphGroups.forEach(group => {
            group.classList.remove(transparentClass);

            // in target group find path and change fill color inline style
            const targetGroupPath = group.querySelector("path");
            targetGroupPath.setAttribute("style", `fill: #ebebeb`);
        })
    }

    // show all
    const showAll = () => {
        showAllLinks();
        showAllGroups();
    }

    // get html content
    let html = document.querySelector("[data-iosyc-graph-content]").innerHTML;

    // in html remove all inline styles inside table tr td etc
    html = html.replace(/style="[^"]*"/g, '');

    // in tags remove spaces like <td > to <td>
    html = html.replace(/<(\w+)\s+>/g, '<$1>');

    // find in html single x or ok between td tags and replace with <span class="graph-x"></span> or <span class="graph-ok"></span>
    const regex = /<td>(x|ok)<\/td>/g;
    html = html.replace(regex, '<td><span class="graph-$1"></span></td>');

    // get group text content by title
    const setGroupTextContent = (groupTitle) => {

        function getSections(html) {
            const sections = [];
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;

            let currentSection = null;
            const elements = tempDiv.children;
            for (let i = 0; i < elements.length; i++) {
            const element = elements[i] as HTMLElement; // Type assertion to HTMLElement
                if (element.tagName === 'H3') {
                    if (currentSection) {
                        sections.push(currentSection);
                    }

                    const title = element.innerText.toLowerCase().replace(/\s/g, '');
                    currentSection = { title: title, content: '' };
                } else {
                    if (currentSection) {
                        currentSection.content += element.outerHTML;
                    }
                }
            }

            if (currentSection) {
                sections.push(currentSection);
            }

            return sections;
        }
        const sections = getSections(html);

        const targetSection = sections.find(section => section.title === groupTitle);

        const graphContent = document.querySelector(".graph-content");
        graphContent.innerHTML = targetSection?.content || '';

    }
    setGroupTextContent("all");

    // highlight target
    const highlightTarget = (targetLink) => {

        // get target name
        const targetName = targetLink.getAttribute("data-graph-target");

            // remove active class from all target connections
            targetLinks.forEach(targetLink => {
                targetLink.classList.remove(activeLinkClass);
            });

            targetLink.classList.add(activeLinkClass);

            // Show group content
            // and put into .graph-content
            setGroupTextContent(targetName);

            if (targetName === "all") {
                showAll();
                return;
            }

            // make all groups and connections transparent
            makeAllGroupsTransparent();
            makeAllLinksTransparent();

            // find target group
            const targetGroup = graph.querySelector(`#g-${targetName}`);
            targetGroup.classList.remove(transparentClass);

            // find all connections which id contains targetName
            // and remove transparent class
            connections.forEach(connection => {
                const connectionId = connection.getAttribute("id");
                const regexPattern = `${targetName}.*`;
                const regex = new RegExp(regexPattern);
                const isMatch = regex.test(connectionId);
                if(isMatch) {
                    connection.classList.remove(transparentClass);

                    // find all groups which their id without "g-" are contained in the connectionId and remove transparent class
                    graphGroups.forEach(group => {
                        const groupId = group.getAttribute("id");
                        const groupIdWithoutG = groupId.slice(2);
                        const isMatch = connectionId.includes(groupIdWithoutG);
                        if(isMatch) {
                            group.classList.remove(transparentClass);

                            const targetGroupPath = group.querySelector("path");
                            targetGroupPath.setAttribute("style", `fill: #ebebeb`);
                        }
                    });
                }
            });

            // in target group find path and change fill color inline style
            const targetGroupPath = targetGroup.querySelector("path");
            targetGroupPath.setAttribute("style", `fill:${mainColor}`);
    }

    // add event listener to all target connections
    targetLinks.forEach(targetLink => {
        targetLink.addEventListener("click", () => {
            highlightTarget(targetLink);
        })
    })

    // add event listener to all groups
    graphGroups.forEach(group => {
        group.addEventListener("click", () => {
            const targetLink = document.querySelector(`[data-graph-target="${group.getAttribute("id").slice(2)}"]`);
            highlightTarget(targetLink);
        })
    })

}
