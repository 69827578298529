export const requestQuote = () => {
    let requestQuoteScrollTimeoutId;
    const checkRequestQuoteYScroll = () => {

        const button = document.getElementById("js-request-quote");
        const footer = document.querySelector(".footer");
        const footerHeight = footer.clientHeight;
        const body = document.body;
        const html = document.documentElement;
        const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const intro = document.querySelector(".intro");

        const ls = localStorage.getItem('gtm');
        let plusBottom = 0;
        if (!ls) {
            plusBottom = 80;
        }

        if ((scrollY + windowHeight) > (documentHeight - footerHeight)) {
            button.style.bottom = footerHeight + "px";
            button.style.position = "absolute";
        } else {
            button.style.bottom = plusBottom + "px";
            button.style.position = "fixed";
        }


        if (intro) {
            const introHeight = document.querySelector(".intro").clientHeight;
            if (scrollY < (windowHeight - introHeight)) {
                button.classList.add("request-quote--from-bottom");
            } else {
                button.classList.remove("request-quote--from-bottom");
            }
        }

    }
    const button = document.getElementById("js-request-quote");
    if (button) {
        checkRequestQuoteYScroll();
        window.addEventListener("scroll", () => {
            clearTimeout(requestQuoteScrollTimeoutId);
            requestQuoteScrollTimeoutId = setTimeout(checkRequestQuoteYScroll, 10);
            checkRequestQuoteYScroll();
        });
        window.addEventListener("resize", () => {
            clearTimeout(requestQuoteScrollTimeoutId);
            requestQuoteScrollTimeoutId = setTimeout(checkRequestQuoteYScroll, 10);
            checkRequestQuoteYScroll();
        });
    }
}
