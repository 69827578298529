class Glr {

    constructor(settings) {

        this.lightbox = null

        this.domImages = null

        this.category = null

        this.actualImage = null

        this.actualHtmlImageList = null

        this.actualImageListArray = null

        // --------------------------------------------

        this.showCover     = true
        this.showDots      = true
        this.galleryRepeat = false
        this.backgroundColor = '#000000'
        this.navigationColor = '#ffffff'

        // --------------------------------------------

        this.settings = settings

        this.domImages = document.querySelectorAll('.lightbox')

        this.initSettings()

        this.initLightbox()

    }

    initSettings() {

        // Show / Hide background cover image
        let showCover = this.settings.showCover
        if(showCover !== undefined) this.showCover = showCover

        // Enable / Disable gallery repeat
        let galleryRepeat = this.settings.galleryRepeat
        if(galleryRepeat !== undefined) this.galleryRepeat = galleryRepeat

        // Background color
        let backgroundColor = this.settings.backgroundColor
        if(backgroundColor !== undefined) this.backgroundColor = backgroundColor

        // Navigation color
        let navigationColor = this.settings.navigationColor
        if(navigationColor !== undefined) this.navigationColor = navigationColor

        // Show dots
        let showDots = this.settings.showDots
        if(showDots !== undefined) this.showDots = showDots

    }

    initLightbox() {

        this.addIDsToDomImages()

        this.domImages.forEach(function(image) {
            image.addEventListener('click', e => {

                e.preventDefault() // Disable <a> link

                this.setCategoryByImage(image)
                this.setActualImageListArray()
                this.setActualHtmlImageList()

                // Create Lightbox
                this.createLightboxDom()
                this.putImagesIntoImageArea()
                this.makeDotList()

                // Init functions
                let imageId = image.getAttribute('data-glr-id')
                this.callAllMainFunctions(imageId)

                // Check navigation
                this.checkNavButtons()
                this.checkDotList()

                this.checkDescriptionBox()

                this.checkCloseLightbox()

                // console.log(this.actualImageListArray)

            }, this)
        }, this)

    }


    callAllMainFunctions(id) {
        if(id > '') {
            this.removeMainImage()
            this.setMainImage(id)
            this.setPrevButton(id)
            this.setNextButton(id)

            if(this.showCover) this.setImageCover()

            this.setActualDot(id);
        }
    }


    // Close btn
    // Close on click on cover
    checkCloseLightbox() {
        let lightbox = document.getElementById('glr-lightbox')
        let cover = document.getElementById('glr-lightbox-cover')
        let closeBtn = document.getElementById('glr-close')
        closeBtn.addEventListener('click', function () {
            lightbox.outerHTML = "";
        })
        cover.addEventListener('click', function (e) {
            e.stopPropagation();
            lightbox.outerHTML = "";
        })
    }


    // Create DOM html layout
    createLightboxDom() {
        this.lightbox = document.createElement('div')
        this.lightbox.id = 'glr-lightbox'
        document.body.appendChild(this.lightbox)
        this.lightbox.innerHTML = this.template_html_main_gallery()
        this.lightbox.style.backgroundColor = this.backgroundColor
    }


    setCategoryByImage(image) {
        this.category = image.getAttribute('data-glr')
    }

    setActualImageListArray() {
        let category = this.category
        let array = []
        this.domImages.forEach(image => {
            let imageCategory = image.getAttribute('data-glr')
            if(imageCategory === category) {
                let object = {}

                let id = image.getAttribute('data-glr-id')

                object.id        = parseInt(id)
                object.href      = image.getAttribute('href')
                object.title     = image.getAttribute('data-glr-title')
                object.desc      = image.getAttribute('data-glr-desc')
                object.thumbnail = this.getThumbnailFromDomImage(image)
                object.category  = image.getAttribute('data-glr')

                array.push(object)
            }
        }, this)

        this.actualImageListArray = array
    }

    setActualHtmlImageList() {
        let array = []
        let images = this.actualImageListArray
        images.forEach(function (image, index)
        {
            let html = this.template_html_single_image_content(
                index,
                image.href,
                image.title,
                image.desc
            )
            array.push(html)
        }, this)
        this.actualHtmlImageList = array
        return false
    }
    getHtmlImagesListArray() {
        return this.actualHtmlImageList
    }

    getFullHtmlImagesList() {
        let htmlList = ''
        this.getHtmlImagesListArray().forEach( (singleImageHtml) => {
            htmlList += singleImageHtml
        })
        return htmlList
    }

    // Get src from child img element
    getThumbnailFromDomImage(image) {
        let childImg = image.getElementsByTagName('img')
        let thumbnail = '';
        if(childImg.length) thumbnail = childImg[0].getAttribute('src')
        return thumbnail
    }

    putImagesIntoImageArea() {
        let htmlImages = this.getFullHtmlImagesList()
        let imageArea = document.getElementById('glr-main-image-area')
        imageArea.innerHTML = htmlImages
    }

    addIDsToDomImages() {

        let categoryList = []
        this.domImages.forEach(function(image) {
            let category = image.getAttribute('data-glr')
            let lastId = categoryList[category]

            if(lastId === undefined) {
                let id = categoryList[category] = 0
                image.setAttribute('data-glr-id', id)
                return false
            }
            if(lastId >= 0) {
                lastId++
                let id = categoryList[category] = lastId
                image.setAttribute('data-glr-id', id)
            }
        })
    }

    getImageObjById(id) {
        let targetImage = document.querySelectorAll('[data-glr-image-id="'+id+'"]')
        return targetImage[0]
    }


    // Main image
    // ------------------------------------------------------------------------
    removeMainImage() {
        let allImages = document.querySelectorAll('[data-glr-image-id]')
        allImages.forEach(function (element) {
            element.classList.remove('glr-active')
        })
    }

    setMainImage(id) {
        let targetImage = this.getImageObjById(id)
        this.actualImage = targetImage
        targetImage.classList.add('glr-active')
    }

    setImageCover() {
        let targetImage = this.actualImage
        let imageCover = document.getElementById('glr-lightbox-cover')
        let imageUrl = targetImage.getAttribute('data-glr-image-url')
        imageCover.style.backgroundImage = "url('" +imageUrl +"')";
    }


    // Navigation arrows
    // ------------------------------------------------------------------------
    setPrevButton(id) {
        id = parseInt(id)
        let btn = document.getElementById('glr-btn-prev')
        let maxNumber = this.getHtmlImagesListArray().length
        let newId = id - 1

        if (newId < 0) {
            if(!this.galleryRepeat) {
                btn.setAttribute('data-glr-target-prev-image-id', '')
                btn.classList.add('glr-btn-nav--disable')
            } else {
                btn.setAttribute('data-glr-target-prev-image-id', (maxNumber - 1).toString())
            }
        } else {
            btn.setAttribute('data-glr-target-prev-image-id', newId.toString())
            if(!this.galleryRepeat) {
                btn.classList.remove('glr-btn-nav--disable')
            }
        }

    }

    setNextButton(id) {
        id = parseInt(id)
        let btn = document.getElementById('glr-btn-next')
        let maxNumber = this.getHtmlImagesListArray().length
        let newId = id + 1

        if (newId > maxNumber - 1) {
            if(!this.galleryRepeat) {
                btn.setAttribute('data-glr-target-next-image-id', '')
                btn.classList.add('glr-btn-nav--disable')
            } else {
                btn.setAttribute('data-glr-target-next-image-id', '0')
            }
        } else {
            btn.setAttribute('data-glr-target-next-image-id', newId.toString())
            if(!this.galleryRepeat) {
                btn.classList.remove('glr-btn-nav--disable')
            }
        }

    }

    checkNavButtons() {


        // remove arrows if is only one image
        if(this.actualImageListArray.length === 1) {
            let btn
            btn = document.getElementById('glr-btn-next')
            btn.outerHTML = "";
            btn = document.getElementById('glr-btn-prev')
            btn.outerHTML = "";
            return false
        }

        const nextButtons = document.querySelectorAll('[data-glr-target-next-image-id]')
        let nextButton = nextButtons[0]
        nextButton.addEventListener('click', e => {
            let targetId = nextButton.getAttribute('data-glr-target-next-image-id')
            this.callAllMainFunctions(targetId)
        }, this)

        const prevButtons = document.querySelectorAll('[data-glr-target-prev-image-id]')
        let prevButton = prevButtons[0]
        prevButton.addEventListener('click', e => {
            let targetId = prevButton.getAttribute('data-glr-target-prev-image-id')
            this.callAllMainFunctions(targetId)
        }, this)

        let body = document.querySelector('body')
        body.addEventListener("keydown", e => {
            if(e.code === 'ArrowRight') {
                let targetId = nextButton.getAttribute('data-glr-target-next-image-id')
                this.callAllMainFunctions(targetId)
            }
            if(e.code === 'ArrowLeft') {
                let targetId = prevButton.getAttribute('data-glr-target-prev-image-id')
                this.callAllMainFunctions(targetId)
            }
        }, this);
    }


    // Dot list
    // ------------------------------------------------------------------------
    makeDotList() {
        if(this.actualImageListArray.length === 1) return false

        let html = ''
        this.actualImageListArray.forEach(function (image){
            html += this.template_html_thumbnail_list(
                image.thumbnail,
                image.href,
                image.id
            );
        }, this)

        let dotList = document.getElementById('glr-dot-list')

        if(!this.showDots) {
            dotList.remove();
            return;
        }

        dotList.innerHTML = html
    }

    checkDotList() {
        if(!this.showDots) return;
        const dotList = document.querySelectorAll('.glr-dot')
        dotList.forEach(dot => {
            dot.addEventListener('click', e => {
                const id = dot.getAttribute('data-glr-image-target-id')
                this.callAllMainFunctions(id)
            }, this)
        }, this)
    }

    setActualDot(id) {
        if(!this.showDots) return;
        if(this.actualImageListArray.length === 1) return false

        let dots = document.querySelectorAll('.glr-dot')
        dots.forEach(function (element){
            element.classList.remove('glr-active')
        })
        let actualDot = document.querySelectorAll('.glr-dot[data-glr-image-target-id="'+id+'"]')
        actualDot[0].classList.add('glr-active')
    }


    // Description box
    // ------------------------------------------------------------------------
    checkDescriptionBox() {
        const descriptionToggle = document.querySelectorAll('.glr-desc-toggle')
        descriptionToggle.forEach(item => {
            item.addEventListener('click', e => {

                let descBoxes = document.querySelectorAll('.glr-desc-box')
                descBoxes.forEach(function (element){
                    element.classList.toggle('glr-active')
                })

            })
        })
    }


    // Templates
    // ------------------------------------------------------------------------

    // Main DOM template
    template_html_main_gallery() {
        return [
            '<div id="glr-lightbox-cover"></div>',
            //'<div class="full-screen"><svg width="100%" height="100%" viewBox="0 0 40 40" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.25;"><g><path d="M11,4l-7,0l0,7" style="fill:none;stroke:#fff;stroke-width:2px;"/><path d="M11,36l-7,0l0,-7" style="fill:none;stroke:#fff;stroke-width:2px;"/><path d="M29,4l7,0l0,7" style="fill:none;stroke:#fff;stroke-width:2px;"/><path d="M29,36l7,0l0,-7" style="fill:none;stroke:#fff;stroke-width:2px;"/></g></svg></div>',
            '<div id="glr-main-image-area"></div>',
            '<div id="glr-close"><svg width="100%" height="100%" viewBox="0 0 40 40" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:1;"><path d="M1,1.001l38,38.025" style="fill:none;stroke:'+this.navigationColor+';stroke-width:2px;"/><path d="M39,1.001l-38,38.025" style="fill:none;stroke:'+this.navigationColor+';stroke-width:2px;"/></svg></div>',
            '<div id="glr-dot-list"></div>',
            '<div id="glr-btn-prev"><svg width="100%" height="100%" viewBox="0 0 40 40" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.25;"><path d="M29.5,1l-19,19l19,19" style="fill:none;stroke:'+this.navigationColor+';stroke-width:2px;"/></svg></div>',
            '<div id="glr-btn-next"><svg width="100%" height="100%" viewBox="0 0 40 40" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.25;"><path d="M10.5,1l19,19l-19,19" style="fill:none;stroke:'+this.navigationColor+';stroke-width:2px;"/></svg></div>',
            //'<div class="thumbnail-list"></div>',
        ].join("\n");
    }

    // Template for inner img - .main-image
    template_html_single_image_content(img_id,img_url,title,desc) {
        let html = '';
        html += '<div class="glr-single-wrapper" data-glr-image-url="'+img_url+'" data-glr-image-id="'+img_id+'">';
        html += '<img src="'+ img_url +'" alt="'+ title +'">';
        if(title > '' || desc > '')
        {
            html += '<div class="glr-desc-toggle"><svg width="100%" height="100%" viewBox="0 0 40 41" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><rect id="icon-info" x="0" y="0.891" width="40" height="40" style="fill:none;"/><path d="M20,3.782c9.443,0 17.109,7.666 17.109,17.109c0,9.443 -7.666,17.109 -17.109,17.109c-9.443,0 -17.109,-7.666 -17.109,-17.109c0,-9.443 7.666,-17.109 17.109,-17.109Zm1.81,16.361c0,-0.999 -0.811,-1.81 -1.81,-1.81c-0.999,0 -1.81,0.811 -1.81,1.81l0,9.047c0,0.999 0.811,1.81 1.81,1.81c0.999,0 1.81,-0.811 1.81,-1.81l0,-9.047Zm-1.81,-8.143c0.999,0 1.81,0.811 1.81,1.81c0,0.998 -0.811,1.809 -1.81,1.809c-0.999,0 -1.81,-0.811 -1.81,-1.809c0,-0.999 0.811,-1.81 1.81,-1.81Z" style="fill:#fff;"/></svg></div>';
            html += '<div class="glr-desc-box glr-active">';
            if (title > '') { html += '<div class="glr-title">' + title + '</div>'; }
            if (desc > '') { html += '<div class="glr-description">' + desc + '</div>'; }
            html += '</div>';
        }
        html += '</div>';
        return html;
    }

    // Template for thumbnail list
    template_html_thumbnail_list(thumbnail,href,id) {
        let html = ''
        html += '<div class="glr-dot" data-glr-image-target-id="'+id+'">'
        html += '<div style="background: '+ this.navigationColor +'">'
        if(thumbnail !== '') {
            html += '<div style="background-image: url(' + thumbnail + ')" class="glr-thumbnail"></div>'
        }
        html += '</div>'
        html += '</div>'
        return html
    }

}

export default Glr;
