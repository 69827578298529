import { graph } from "./js/graph";
import { particlesFn } from "./js/particlesSettings.js";
import { requestQuote } from "./js/requestQuote";
import { gdprCookiesForm } from "./js/gdprCookiesForm";
// @ts-ignore
import AOS from 'aos';
// @ts-ignore
import Swiper from 'swiper';
import Glr from "./js/modules/glr/glr.js";
// @ts-ignore
import Alpine from 'alpinejs'
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

// @ts-ignore
window.Alpine = Alpine
Alpine.start()


/**
 * Header menu
 */
const headerMenu = () => {

    const menuShowCta = document.getElementById("js-menu-show");
    const menuCloseCta = document.getElementById("js-menu-close");
    const html = document.querySelector("html");
    const menuWrapper = document.querySelector(".menu-wrapper");
    const menu = document.getElementById("js-menu");

    if (menuShowCta && menuCloseCta && menuWrapper) {

        const hideMenu = () => {
            menu.classList.remove("menu--active");
            setTimeout(function () {
                html.classList.remove("overflow--hidden");
            }, 500);
        }

        menuShowCta.addEventListener("click", () => {
            menu.classList.add("menu--active");
            html.classList.add("overflow--hidden");
        });

        menuCloseCta.addEventListener("click", () => {
            hideMenu();
        });

        menuWrapper.addEventListener("click", (e) => {
            e.stopPropagation();
            hideMenu();
        });
    }
}

headerMenu();

/**
 * Header Slideshow
 */
const hpHeaderParallax = new Swiper(".intro-slideshow", {
    modules: [Navigation, Pagination, Autoplay, EffectFade],
    fadeEffect: { crossFade: true },
    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    },
    effect: 'fade',
    speed: 1200,
    loop: true,
    navigation: {
        nextEl: ".intro-slideshow__nav--next",
        prevEl: ".intro-slideshow__nav--prev",
    },
    pagination: {
        el: ".intro-slideshow__dots",
        clickable: true,
    },
});


/**
 * header menu scroll
 */
const headerMenuScroll = () => {
    let scrollTimeoutId;
    const checkHeaderYScroll = () => {
        const y = window.scrollY;
        const header = document.querySelector(".main-header");
        if (header) {
            if (y > 500) {
                header.classList.add("main-header--from-top");
            } else {
                header.classList.remove("main-header--from-top");
            }
        }
    }
    checkHeaderYScroll();
    window.addEventListener("scroll", () => {
        clearTimeout(scrollTimeoutId);
        scrollTimeoutId = setTimeout(checkHeaderYScroll, 100);
    })
}

headerMenuScroll();

/**
 * Particles
 */
particlesFn();


/**
 * Language menu
 */
const languageMenu = () => {
    const langShowCta = document.getElementById("js-lang");
    const langList = document.querySelector("[data-lang-list]");
    if (langList && langShowCta) {
        langShowCta.addEventListener("click", (event) => {
            event.stopPropagation();
            langList.classList.toggle("main-header__lang-list--active");
        });
        window.addEventListener("click", () => {
            langList.classList.remove("main-header__lang-list--active");
        });
    }
}

languageMenu();

/**
 * Logos parallax
 */
const logos = new Swiper(".js-logos", {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    navigation: {
        nextEl: ".swiper-nav-next",
        prevEl: ".swiper-nav-prev",
    },
    autoplay: {
        delay: 1500,
        disableOnInteraction: true,
    },
    speed: 1300,
    breakpoints: {
        "@0.00": {
            slidesPerView: 1,
        },
        "@0.75": {
            slidesPerView: 3,
        },
        "@1.00": {
            slidesPerView: 5,
        },
        "@1.50": {
            slidesPerView: 6,
        },
    }
});

/**
 * Page parallax
 */
const pageParallax = new Swiper(".page-parallax", {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 20,
    loop: false, // Initially set loop to false
    navigation: {
        nextEl: ".swiper-nav-next",
        prevEl: ".swiper-nav-prev",
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    }
});


/**
 * Intro Video
 */
const introVideo = () => {

    /**
     * Loaded animation
     */
    const videoLoaded = () => {
        const videoBg = document.querySelector(".video-bg");
        const introVideo = document.getElementById("intro-video") as HTMLVideoElement;
        if (introVideo) {
            introVideo.onloadeddata = function () {
                if (introVideo.readyState >= 2) {
                    videoBg.classList.add("animateFadeIn");
                }
            }
        }
    }

    videoLoaded();

    /**
     * Auto resolution
     */
    function introVideoResolution() {
        const v = [
            "/img/video/intro_360p.mp4",
            "/img/video/intro_540p.mp4",
            "/img/video/intro_720p.mp4"
        ]
        const video = document.querySelector('.intro video') as HTMLVideoElement;
        const source = document.querySelector('.intro video source');
        if (video) {
            if (window.innerWidth <= 500) source.setAttribute("src", v[0]);
            else if (window.innerWidth <= 1024) source.setAttribute("src", v[1]);
            else source.setAttribute("src", v[2]);
            video.load();
        }
    }
    introVideoResolution();

}
introVideo();


/**
 * Request quote
 */
requestQuote();



/**
 * Scroll to anchor
 */
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const yOffset = 0;

        const hrefValue = this.getAttribute('href');
        if (hrefValue.startsWith('#')) {
            const element = document.querySelector(hrefValue);
            if (element) {
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            } else {
                console.error('No element found with the given ID');
            }
        } else {
            console.error('Invalid href value');
        }

    })
})


/**
 * GDPR cookies selection form
 */
gdprCookiesForm();


/**
 * Cookies bar
 */
const cookieBar = () => {
    const gdprApprove = document.getElementById('gdpr-approve');
    if (gdprApprove) {
        gdprApprove.addEventListener('click', (event) => {
            localStorage.setItem('gtm', 'analytics');
            window.location.reload();
        })
    }
    if (!localStorage.getItem('gtm')) {
        const cookieBar = document.querySelector('.cookies-bar');
        if (cookieBar) {
            cookieBar.classList.remove('hidden');
        }
    }

    // remove gtm from localstorage, if value is not equal to any presented value
    const ls = localStorage.getItem('gtm');
    if (!(ls === 'default' || ls === 'analytics')) {
        localStorage.removeItem('gtm')
    }
}
cookieBar();


/**
 * Editor gallery
 */
const editorGallery = () => {
    const galleries = document.querySelectorAll(".editor ul.gallery");
    galleries.forEach((gallery) => {
        const images = gallery.querySelectorAll("a");
        images.forEach((image) => {
            image.classList.add("lightbox");
        });
    })
}
editorGallery();


const editorSwiper = () => {
    const galleries = document.querySelectorAll(".editor ul.gallery-slideshow");
    galleries.forEach((gallery) => {

        // todo - rand string to separate galleries on page
        const rand = Math.floor(Math.random() * 1000);

        let org_html = gallery.innerHTML;
        gallery.innerHTML = "<div class='swiper page-parallax-gallery swiper--padding-x'><div class='swiper-wrapper'>" + org_html + "</div>" +
            "<div class='swiper-nav-next'></div><div class='swiper-nav-prev'></div></div>";

        const lis = gallery.querySelectorAll("li");
        lis.forEach((li) => {

            const a = li.querySelector("a");
            a.classList.add("lightbox");
            a.setAttribute("data-glr", rand.toString());

            // replace li > div
            const d = document.createElement('div');
            d.classList.add("swiper-slide");
            d.innerHTML = li.innerHTML;
            li.parentNode.replaceChild(d, li);

        })

        // replace ul > div
        const d = document.createElement('div');
        d.innerHTML = gallery.innerHTML;
        gallery.parentNode.replaceChild(d, gallery);

    });

    /**
     * Page parallax
     */
    const pageParallax = new Swiper(".page-parallax-gallery", {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 20,
        loop: false,
        navigation: {
            nextEl: ".swiper-nav-next",
            prevEl: ".swiper-nav-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 1,
                // spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                // spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                // spaceBetween: 50,
            },
        }
    });



    /**
     * Page parallax
     */
    const ioSyncPageParallax = new Swiper(".iosync-page-parallax", {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 20,
        loop: false, // Initially set loop to false
        navigation: {
            nextEl: ".iosync-swiper-nav-next",
            prevEl: ".iosync-swiper-nav-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
        }});

}
editorSwiper();


/**
 * Glr
 */
let glr = new Glr({
    showCover: false,
    galleryRepeat: true,
    backgroundColor: "rgba(0,0,0,0.55)",
    navigationColor: "#ffffff",
    showDots: false
})


/**
 * AOS
 */
AOS.init();


/**
 * IoFramework
 */
const ioFramework = () => {
    const details = document.querySelectorAll("[data-show-more]");
    details.forEach((detail) => {

        const box = detail.querySelector("div");
        box.classList.add("show-more-wrapper");
        box.classList.add("hidden");

        const button = detail.querySelector("button");
        button.classList.add("button");
        button.classList.add("button--primary");
        button.addEventListener("click", () => {
            box.classList.toggle("hidden");
            button.classList.add("hidden");
        });

    });
}
ioFramework();


/**
 * IoFramework Scopes
 */
const ioFrameworkScopes = () => {

    const tabScrollLinks = document.querySelectorAll("[data-iof-scope-scroll-target]");
    tabScrollLinks.forEach(tabLink => {
        tabLink.addEventListener("click", () => {
            const target = tabLink.getAttribute("data-iof-scope-scroll-target");

            // scroll to scopes
            const yOffset = -100;
            const element = document.querySelector(".iof-scopes");
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });

            selectTab(target);
        });
    });

    const tabLinks = document.querySelectorAll("[data-iof-scope-target]");
    tabLinks.forEach(tabLink => {
        tabLink.addEventListener("click", () => {
            const target = tabLink.getAttribute("data-iof-scope-target");
            selectTab(target);
        });
    });

    function selectFirstTab() {
        const tabLink = document.querySelector("[data-iof-scope-target]");
        const tab = document.querySelector("[data-iof-scope-id]");
        if (tabLink && tab) {
            tabLink.classList.add("iof-scopes__nav-item--active");
            tab.classList.add("iof-scope__tab--active");
        }
    }

    function hideAllTabs() {
        const tabs = document.querySelectorAll("[data-iof-scope-id]");
        const tabLinks = document.querySelectorAll("[data-iof-scope-target]");
        tabs.forEach(tab => {
            tab.classList.remove("iof-scope__tab--active");
        })
        tabLinks.forEach(tabLink => {
            tabLink.classList.remove("iof-scopes__nav-item--active");
        });
    }

    function selectTab(section) {

        hideAllTabs();

        const tabLink = document.querySelector(`[data-iof-scope-target="${section}"]`);
        const tab = document.querySelector(`[data-iof-scope-id="${section}"]`);
        tabLink.classList.add("iof-scopes__nav-item--active");
        tab.classList.add("iof-scope__tab--active");
        tab.classList.toggle("iof-scope__tab--mobile-active");

    }

    hideAllTabs();
    selectFirstTab();



    const titles = document.querySelectorAll(".iof-scope__tab__title");
    titles.forEach(title => {
        title.addEventListener("click", () => {
            const tab = title.closest(".iof-scope__tab");
            tab.classList.toggle("iof-scope__tab--mobile-active");
        })
    })

}
ioFrameworkScopes();


/**
 * Intro 3d card
 */
const intro3dCard = () => {

    const card = document.querySelector(".intro-3d-card") as HTMLElement;
    const motionMatchMedia = window.matchMedia("(prefers-reduced-motion)");
    const THRESHOLD = 3;

    if (!card) return;

    function handleHover(e) {
        const { clientX, clientY, currentTarget } = e;
        const { clientWidth, clientHeight, offsetLeft, offsetTop } = currentTarget;

        const horizontal = (clientX - offsetLeft) / clientWidth;
        const vertical = (clientY - offsetTop) / clientHeight;
        const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
        const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

        card.style.transform = `perspective(1000px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
    }

    function resetStyles(e) {
        card.style.transform = `perspective(1000px) rotateX(0deg) rotateY(-3deg)`;
    }

    if (!motionMatchMedia.matches) {
        card.addEventListener("mousemove", handleHover);
        card.addEventListener("mouseleave", resetStyles);
    }

};
intro3dCard();


/** Graph */
graph();



/** Editor - Sync counter */
const editorSyncCounter = () => {
    function calculateDaysSince(dateString: string) {
        if (!dateString) {
            return 0;
        }

        const date = new Date(dateString);
        const now = new Date();

        if (isNaN(date.getTime()) || date > now) {
            return 0;
        }

        const diff = now.getTime() - date.getTime();
        return Math.floor(diff / (1000 * 60 * 60 * 24));
    }

    function updateSpans() {
        const spans = document.querySelectorAll('span[data-sync-counter]');
        spans.forEach(span => {
            const date = span.getAttribute('data-sync-counter');
            const daysSince = calculateDaysSince(date);
            span.textContent = daysSince.toString();
        });
    }

    updateSpans();
}
editorSyncCounter();
