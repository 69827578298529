export const gdprCookiesForm = () => {
    document.querySelectorAll("input[name='gdprType']")
      .forEach((input) => {
        input.addEventListener('change', (event) => {
            const inputElement = event.target as HTMLInputElement;
            if (inputElement.value === "analytics") {
                const analyticsChecked = inputElement.checked;
                if (analyticsChecked) {
                    localStorage.setItem('gtm', "analytics");
                } else {
                    localStorage.setItem('gtm', "default");
                }
            }
        });
        const ls = localStorage.getItem('gtm');
        if (ls === 'default' || ls === null) {
            if (input instanceof HTMLInputElement && input.value === 'default') {
                input.checked = true;
            }
        } else {
            if (input instanceof HTMLInputElement && input.value === ls) {
                input.checked = true;
            }
        }
    });
    const cookiesSettings = document.querySelector(".cookies-settings");
    const cookiesButtonOk = document.querySelector(".cookies-button-ok");
    if (cookiesButtonOk && cookiesSettings) {
        cookiesButtonOk.addEventListener("click", () => {
            cookiesSettings.classList.add("hidden");
            const ls = localStorage.getItem('gtm');
            if (!ls) {
                localStorage.setItem('gtm', "default");
            }
            window.location.reload();
        });
    }

    const cookiesSettingsLinks = document.querySelectorAll("[data-open-cookies-setting]");
    cookiesSettingsLinks.forEach((link) => {
        link.addEventListener("click", () => {
            cookiesSettings.classList.remove("hidden");
        });
    });
}
